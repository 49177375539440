import * as React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

export default function GenresComp({ label, genres, setGenres, allGenres }) {

    const handleChange = (event, newValue) => {
        setGenres(newValue)
    }

    const isLikelyKeyboardless = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }

    return (
        <Autocomplete
            multiple
            options={allGenres}
            getOptionLabel={(option) => option}
            value={genres} // Use 'value' instead of 'defaultValue' to make the component controlled
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder="Genres..."
                    inputProps={{...params.inputProps, readOnly: isLikelyKeyboardless()}}
                />
            )}
        />
    )
}
