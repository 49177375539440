import { create } from 'zustand'

export const useStore = create((set, get) => ({
    films: [],
    setFilms: (films) => set({ films: films }),
    hasMore: false,
    setHasMore: (hasMore) => set({hasMore: hasMore}),
    genres: [],
    setGenres: (genres) => set({ genres: genres }),
    // countries: [],
    // setCountries: (countries) => set({ countries: countries }),
    languages: [],
    setLanguages: (languages) => set({ languages: languages }),

    error: null,
    setError: (error) => set({ error: error }),

    loading: false,
    setLoading: (value) => set({ loading: value }),
    changed: false,
    setChanged: (value) => set({ changed: value }),
    reload: false,
    causeReload: () => set((state) => ({ reload: state.reload + 1 })),
}))