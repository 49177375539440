import React from 'react'
import TextField from '@mui/material/TextField'

export default function DebouncedTextField({ label, text: textProp, setText: setTextProp, delay }) {
    const [text, setText] = React.useState('')

    React.useEffect(() => {
        setText(textProp)
    }, [textProp])

    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (textProp !== text) {
                setTextProp(text)
            }
        }, delay)
        return () => clearTimeout(timer)
    }, [text])

    const handleChange = (event) => {
        setText(event.target.value)
    }

    return (
        <TextField
            value={text}
            onChange={handleChange}
            label={label}
        />
    )
}

