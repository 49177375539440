export function deepEqual(a, b) {
    if (a === b) {
        return true;
    }

    if (typeof a !== typeof b) {
        return false;
    }

    if (typeof a === 'object') {
        // Handle null cases
        if (a === null || b === null) {
            return false;
        }
        
        // If they are arrays, compare their length
        if (Array.isArray(a) && Array.isArray(b)) {
            if (a.length !== b.length) {
                return false;
            }

            // Compare each element
            for (let i = 0; i < a.length; i++) {
                if (!deepEqual(a[i], b[i])) {
                    return false;
                }
            }

            return true;
        }

        // If they are objects, compare their keys and values
        let keysA = Object.keys(a);
        let keysB = Object.keys(b);

        if (keysA.length !== keysB.length) {
            return false;
        }

        for (let key of keysA) {
            if (!keysB.includes(key) || !deepEqual(a[key], b[key])) {
                return false;
            }
        }

        return true;
    }

    return false;  // If a and b aren't both objects, arrays, or identical primitives
}