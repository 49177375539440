import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

export default function LanguageComp({ language, setLanguage, languages }) {

    const handleChange = (event, newValue) => {
        setLanguage(newValue !== null ? newValue : '')
    }

    return (
        <Autocomplete
            options={languages}
            getOptionLabel={(option) => option}
            value={language !== '' ? language : null}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Language"
                    placeholder="Language..."
                    inputProps={{...params.inputProps, readOnly: false}}
                />
            )}
        />
    )
}
