import React from 'react'

import styled from 'styled-components'

import VideocamIcon from '@mui/icons-material/Videocam'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy'
import LanguageIcon from '@mui/icons-material/Language'

export default function FilmComp({ film, setDirector, setActor, addReqGenre, /*setCountry,*/ setLanguage }) {
    return (
        <ListItemContainer>
            <a
                href={'https://letterboxd.com/film/' + film.url}
                target="_blank"
                rel="noreferrer"
            >
                <ItemImage src={film.poster} />
            </a>
            <FilmProps>
                <FilmTitleCont>
                    <FilmLink
                        href={'https://letterboxd.com/film/' + film.url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FilmTitle>{film.title}</FilmTitle>
                    </FilmLink>
                    ({film.year})
                </FilmTitleCont>
                <RatingNumCont>
                    <RatingCont>{film.rating.toFixed(1)}</RatingCont>
                    <NumCont># {film.num}</NumCont>
                </RatingNumCont>
                <ListItemsProp
                    PropIcon={VideocamIcon}
                    items={film.directors}
                    more={film.moreDirectors}
                    itemClicked={setDirector}
                />
                <ListItemsProp
                    PropIcon={PeopleAltIcon}
                    items={film.actors}
                    more={false}
                    itemClicked={setActor}
                />
                <ListItemsProp
                    PropIcon={TheaterComedyIcon}
                    items={film.genres}
                    more={false}
                    itemClicked={addReqGenre}
                />
                {/* <ListItemsProp
                    PropIcon={LanguageIcon}
                    items={film.countries}
                    itemClicked={setCountry}
                /> */}
                <ListItemsProp
                    PropIcon={LanguageIcon}
                    items={film.language ? [film.language] : []}
                    itemClicked={setLanguage}
                />
            </FilmProps>
        </ListItemContainer>
    )
}

const ListItemContainer = styled.div`
  display: flex;
  padding: 15px 10px 15px 10px;
  border-bottom: 1px solid #3b3b3b;
`;

const ItemImage = styled.img`
  width: 115px;
  height: 172.5px;
  margin-right: 10px;
  object-fit: cover;
  border: 1px solid #3b3b3b;
`;

const FilmProps = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 7px;
`

const FilmLink = styled.a`
  color: inherit;
  text-decoration: none;
  &:hover {
    color: #ccc;
  }
`

const FilmTitleCont = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

const FilmTitle = styled.div`
  font-size: 115%;
  /* background-color: #333; */
`
const RatingNumCont = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
    color: #ddd;
`

const RatingCont = styled.div`
    font-size: 130%;
    color: #fdc187;
`

const NumCont = styled.div`
    font-size: 110%;
`

function ListItemsProp({ PropIcon, items, more, itemClicked }) {
    return (
        <ListItemPropCont>
            {PropIcon && 
                <PropIcon 
                    style={{fontSize: 20, color: '#777'}}
                />
            }
            <ListItemCont>
                {items.map((item, index) => (
                    <ListItem
                        key={item}
                        onClick={() => itemClicked(item)}
                    >
                        {(index < items.length - 1 || more) ? item + ',' : item}
                    </ListItem>
                ))}
                { more && '...'}
            </ListItemCont>
        </ListItemPropCont>
    )
}

const ListItemPropCont = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const ListItemCont = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
`

const ListItem = styled.span`
    cursor: pointer;
    color: #ddd;
    &:hover {
        color: #aaa;
    }
`
