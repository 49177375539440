import React from 'react'

import useMediaQuery from '@mui/material/useMediaQuery'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import axios from 'axios'
import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import InfiniteScroll from 'react-infinite-scroller'
import { BrowserRouter, Routes, Route, Navigate, useLocation, useSearchParams } from 'react-router-dom'

import * as AppConst from './AppConst'
import { useStore } from './useStore5'
import LeftPanel from './LeftPanel'
import FilmsList from './FilmsList'
import { getParams, convertParams } from './ParamUtils'
import * as Styles from './Styles'

const isLikelyKeyboardless = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

let DarkScrollBars = createGlobalStyle`
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #2f2f2f;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  body {
    scrollbar-color: #888 #2f2f2f;
    scrollbar-width: thin;
  }
`

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: Styles.primary,
        },
        // secondary: {
        //     main: '#888888',
        // },
    },
    // components: {
    //     MuiSlider: {
    //         styleOverrides: {
    //             thumb: {
    //                 color: '#686868',
    //             },
    //             track: {
    //                 color: '#686868',
    //             },
    //             rail: {
    //                 color: '#686868',
    //             },
    //         },
    //     },
    // },
})

export default function MoviesApp() {
    return (
        <>
            {!isLikelyKeyboardless() && <DarkScrollBars />}
            <ThemeProvider theme={darkTheme}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/main" element={<MoviesLayout />} />
                        <Route path="*" element={<Navigate to={`/main`} replace />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </>
    )
}

const Container = styled.div`
  display: flex;
  height: 100vh;
  background: #000;
  color: #fff;
`;

const RightPanel = styled.div`
  flex: 4;
  overflow-y: scroll;
`;

const LoadingMessage = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px;
`

function MoviesLayout() {
    const isDesktop = useMediaQuery('(min-width: 750px)')

    const [searchParams, setSearchParams] = useSearchParams()
    const params = getParams(searchParams)

    const locationKey = useLocation().key

    const [history, _] = React.useState([])
    const [previousKey, setPreviousKey] = React.useState()
    const [scrollTo, setScrollTo] = React.useState(-1)

    const state = useStore()

    console.log('MoviesLayout()'
        + ', loading: ' + state.loading
        + ', changed: ' + state.changed
        + ', reload: ' + state.reload
    )

    // console.log('history size: ' + state.history.length)

    const rightPanelRef = React.useRef(null)

    React.useEffect(() => {
        console.log('useEffect() searchParams' +
            ', sort: ' + params.sort
        )
        if (!state.loading) {
            fetchOrGetFromHistory()
        } else {
            console.log('skip fetchData')
            state.setChanged(true)
        }
    }, [params.sort, params.yearFrom, params.yearTo, params.title, params.director, params.actor,
        params.reqGenres.join('_'), params.skipGenres.join('_'), /*params.country,*/ params.language])

    React.useEffect(() => {
        console.log('useEffect() reload' +
            ', sort: ' + params.sort
        )
        if (!state.loading && state.changed) {
            fetchOrGetFromHistory()
        } else {
            console.log('skip fetchData')
        }
    }, [state.reload])
    
    function fetchOrGetFromHistory() {
        const historyState = getHistoryState(history, locationKey)
        console.log('historyState: ' + historyState)
        if (historyState === undefined) {
            fetchData(0)
        } else {
            state.setFilms(historyState.films)
            state.setHasMore(historyState.hasMore)
            setScrollTo(historyState.scroll)

            state.setChanged(false)
        }
    }

    const loadMore = React.useCallback(() => {
        console.log('loadMore()' +
            ', sort: ' + params.sort
        )
        if (!state.loading && !state.changed) {
            fetchData(state.films.length)
        } else {
            console.log('skip fetchData')
        }
    }, [state.loading, state.changed, state.films.length])

    function fetchData(offset) {
        console.log('> axios get' +
            ', offset: ' + offset +
            ', sort: ' + params.sort
        )
        state.setLoading(true)
        state.setChanged(false)
        let reqStr = `/lb_movies` +
            `?sort=${params.sort}` +
            `&year_from=${params.yearFrom !== AppConst.minYear ? params.yearFrom : ''}` +
            `&year_to=${params.yearTo !== AppConst.maxYear ? params.yearTo : ''}` +
            `&title=${params.title}` +
            `&director=${params.director}` +
            `&actor=${params.actor}` +
            `&req_genres=${params.reqGenres.join('_')}` +
            `&skip_genres=${params.skipGenres.join('_')}` +
            // `&country=${params.country}` +
            `&language=${params.language}` +
            `&offset=${offset}` +
            `&init=${state.genres.length === 0}`
        axios.get(reqStr)
            .then(response => {
                console.log('<<< axios response' +
                    ', offset: ' + offset +
                    ', sort: ' + params.sort
                )

                const data = response.data
                state.setFilms(offset === 0 ? data.films : [...state.films, ...data.films])
                state.setHasMore(data.hasMore)
                if (data.genres) {
                    state.setGenres(data.genres)
                    // state.setCountries(data.countries)
                    state.setLanguages(data.languages)
                }
                state.setLoading(false)
                state.setError(null)

                state.causeReload()

                if (offset === 0) {
                    if (rightPanelRef.current) {
                        rightPanelRef.current.scrollTop = 0
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching data: ', error)

                state.setFilms([])
                state.setHasMore(false)
                state.setLoading(false)
                state.setError(error)

                state.setChanged(false)
            })
    }

    React.useEffect(() => {
        // console.log('------------ ' + rightPanelRef.current?.scrollTop + ', ' + state.films.length)
        if (previousKey) {
            addHistoryState(history, previousKey, {
                films: state.films,
                hasMore: state.hasMore,
                scroll: rightPanelRef.current.scrollTop,
            })
        }
        setPreviousKey(locationKey)
    }, [locationKey])

    React.useEffect(() => {
        if (scrollTo !== -1) {
            if (rightPanelRef.current) {
                // console.log('scroll to: ' + scrollTo)
                rightPanelRef.current.scrollTop = scrollTo
                setScrollTo(-1)
            }
        }
    }, [scrollTo])

    const setParams = React.useCallback((params2) => {
        setSearchParams(convertParams({
            ...params,
            ...params2,
        }))
    }, [params, setSearchParams])

    if (isDesktop) {
        return (
            <Container>
                <LeftPanel
                    isDesktop={isDesktop}
                    state={state}
                    params={params}
                    setParams={setParams}
                />
                <RightPanel
                    ref={rightPanelRef}
                >
                    <InfiniteScroll
                        loadMore={loadMore}
                        hasMore={state.hasMore}
                        loader={<LoadingMessage key='loader'>Loading...</LoadingMessage>}
                        useWindow={false}
                    >
                        <FilmsList
                            state={state}
                            params={params}
                            setParams={setParams}
                        />
                    </InfiniteScroll>
                </RightPanel>
            </Container>
        )
    } else {
        return (
            <Container>
                <RightPanel
                    ref={rightPanelRef}
                >
                    <InfiniteScroll
                        loadMore={loadMore}
                        hasMore={state.hasMore}
                        loader={<LoadingMessage key='loader'>Loading...</LoadingMessage>}
                        useWindow={false}
                    >
                        <LeftPanel
                            isDesktop={isDesktop}
                            state={state}
                            params={params}
                            setParams={setParams}
                        />
                        <FilmsList
                            state={state}
                            params={params}
                            setParams={setParams}
                        />
                    </InfiniteScroll>
                </RightPanel>
            </Container>
        )
    }
}

function addHistoryState(history, stateId, state) {
    let index = history.findIndex((item) => item.id === stateId)
    if (index !== -1) {
        history.splice(index, 1)
    }
    history.push({
        id: stateId,
        state: state,
    })
    if (history.length > 10) {
        history.shift()
    }
}

function getHistoryState(history, stateId) {
    return history.find((item) => item.id === stateId)?.state
}


