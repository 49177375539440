import React from 'react'

import styled from 'styled-components'

import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'

import ToolBar from './Toolbar'
import DebouncedTextField from './DebouncedTextField'
import DirActorsComp from './DirActorsComp'
import YearsComp from './YearsComp2'
import GenresComp from './GenresComp'
import SortComp from './SortComp2'
// import CountryComp from './CountryComp'
import LanguageComp from './LanguageComp'

import * as AppConst from './AppConst'
import * as Utils from './Utils'

export default function LeftPanel({isDesktop, state, params, setParams}) {
    function setSort(sort) {
        console.log('+++ sort changed')
        setParams({
            sort: sort,
        })
    }
    function setYears(years) {
        console.log('+++ years changed')
        setParams({
            yearFrom: years[0],
            yearTo: years[1],
        })
    }
    function setTitle(title) {
        console.log('+++ title changed')
        setParams({
            title: title,
        })
    }
    function setDirector(director) {
        console.log('+++ director changed')
        setParams({
            director: director,
        })
    }
    function setActor(actor) {
        console.log('+++ actor changed')
        setParams({
            actor: actor,
        })
    }
    function setReqGenres(genres) {
        console.log('+++ req genres changed')
        setParams({
            reqGenres: genres,
        })
    }
    function setSkipGenres(genres) {
        console.log('+++ skip genres changed')
        setParams({
            skipGenres: genres,
        })
    }
    // function setCountry(country) {
    //     console.log('+++ country changed')
    //     setParams({
    //         country: country,
    //     })
    // }
    function setLanguage(language) {
        console.log('+++ language changed')
        setParams({
            language: language,
        })
    }
    return (
        <LeftPanelDiv isDesktop={isDesktop}>
            <ToolBar
                params={params}
                setParams={setParams}
            />
            <FilterCont
                enabled={params.sort !== AppConst.sort}
                clear={() => setSort(AppConst.sort)}
            >
                <SortComp
                    sort={params.sort}
                    setSort={setSort}
                />
            </FilterCont>
            <FilterCont
                enabled={params.yearFrom !== AppConst.minYear || params.yearTo !== AppConst.maxYear}
                clear={() => setYears([AppConst.minYear, AppConst.maxYear])}
            >
                <YearsComp
                    yearRange={[params.yearFrom, params.yearTo]}
                    setYearRange={setYears}
                />
            </FilterCont>
            <FilterCont
                enabled={params.title !== AppConst.title}
                clear={() => setTitle(AppConst.title)}
            >
                <DebouncedTextField
                    label='Title'
                    text={params.title}
                    setText={setTitle}
                    delay={1000}
                />
            </FilterCont>
            <FilterCont
                enabled={params.director !== AppConst.director}
                clear={() => setDirector(AppConst.director)}
            >
                <DirActorsComp
                    endPoint='directors'
                    label='Director'
                    value={params.director}
                    setValue={setDirector}
                />
            </FilterCont>
            <FilterCont
                enabled={params.actor !== AppConst.actor}
                clear={() => setActor(AppConst.actor)}
            >
                <DirActorsComp
                    endPoint='actors'
                    label='Actor'
                    value={params.actor}
                    setValue={setActor}
                />
            </FilterCont>
            <FilterCont
                enabled={!Utils.deepEqual(params.reqGenres, AppConst.reqGenres)}
                clear={() => setReqGenres(AppConst.reqGenres)}
            >
                <GenresComp
                    label='Required genres'
                    genres={params.reqGenres}
                    setGenres={setReqGenres}
                    allGenres={state.genres}
                />
            </FilterCont>
            <FilterCont
                enabled={!Utils.deepEqual(params.skipGenres, AppConst.skipGenres)}
                clear={() => setSkipGenres(AppConst.skipGenres)}
            >
                <GenresComp
                    label='Skipped genres'
                    genres={params.skipGenres}
                    setGenres={setSkipGenres}
                    allGenres={state.genres}
                />
            </FilterCont>
            {/* <FilterCont
                enabled={params.country !== AppConst.country}
                clear={() => setCountry(AppConst.country)}
            >
                <CountryComp
                    country={params.country}
                    setCountry={setCountry}
                    countries={state.countries}
                />
            </FilterCont> */}
            <FilterCont
                enabled={params.language !== AppConst.language}
                clear={() => setLanguage(AppConst.language)}
            >
                <LanguageComp
                    language={params.language}
                    setLanguage={setLanguage}
                    languages={state.languages}
                />
            </FilterCont>
        </LeftPanelDiv>
    )
}

const LeftPanelDiv = styled.div`
  flex: 1.5;
  display: flex;
  flex-direction: column;
  padding: 10px;
  /* padding-top: 20px; */
  gap: 12px;

  border-right: ${props => props.isDesktop ? '1px solid #3b3b3b' : 'none'};
  border-bottom: ${props => props.isDesktop ? 'none' : '1px solid #3b3b3b'};

  overflow-y: auto;
`

function FilterCont({children, enabled, clear}) {
    return (
        <FilterContDiv>
            <ChildCont>
                {children}
            </ChildCont>
            <IconButton
                color="primary"
                disabled={!enabled}
                onClick={clear}
            >
                <ClearIcon />
            </IconButton>
        </FilterContDiv>
    )
}

const FilterContDiv = styled.div`
  display: flex;
  gap: 5px;
`

const ChildCont = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`
