export const sort = 1
export const minSort = 0
export const maxSort = 3

export const minYear = 1920
export const maxYear = new Date().getFullYear()

export const title = ''

export const director = ''

export const actor = ''

export const reqGenres = []

export const skipGenres = []

// export const country = ''

export const language = ''




