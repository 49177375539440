import React from 'react'
import axios from 'axios'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { debounce } from '@mui/material/utils'

export default function DirActorsComp({endPoint, label, value: valueProp, setValue: setValueProp}) {
    // console.log(value)

    const [value, setValue] = React.useState(null)
    const [inputValue, setInputValue] = React.useState('')
    const [options, setOptions] = React.useState([])

    React.useEffect(() => {
        setValue(propToValue(valueProp))
        setInputValue(valueProp)
    }, [valueProp])

    function fetchDirectors(inputValue, callback) {
        axios.get(`/${endPoint}?search=${inputValue}`)
            .then(response => {
                callback(response.data)
            })
            .catch(error => console.error(error))
    }

    const doFetch = React.useMemo(
        () => debounce(fetchDirectors, 500),
        [])

    React.useEffect(() => {
        if (inputValue === '') {
            setOptions(value ? [value] : [])
            return undefined;
        }

        doFetch(inputValue, (results) => {
            let newOptions = []

            if (value) {
                newOptions = [value]
            }

            if (results) {
                newOptions = [...newOptions, ...results]
            }

            setOptions(newOptions)
        })
    }, [value, inputValue, doFetch])

    const handleOnChange = (event, newValue) => {
        // console.log('setValue')
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)

        const valueProp2 = valueToProp(newValue)
        if (valueProp2 !== valueProp) {
            setValueProp(valueProp2)
        }
    }

    return (
        <Autocomplete
            getOptionLabel={(option) => option}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText="No matches"
            onChange={handleOnChange}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue)
            }}
            renderInput={(params) => (
                <TextField {...params} label={label} fullWidth />
            )}
        />
    )
}

function propToValue(prop) {
    return prop === '' ? null : prop
}

function valueToProp(value) {
    return value === null ? '' : value
}
