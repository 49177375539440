import React from 'react'

import styled from 'styled-components'

import FilmComp from './FilmComp'

export default function FilmsList({state, params, setParams}) {
    if (state.films.length > 0) {
        return (
            state.films.map((film) => (
                <FilmComp
                    key={film.id}
                    film={film}
                    setDirector={(director) => {
                        console.log('+++ director changed')
                        setParams({
                            director: director,
                        })
                    }}
                    setActor={(actor) => {
                        console.log('+++ actor changed')
                        setParams({
                            actor: actor,
                        })
                    }}
                    addReqGenre={(genre) => {
                        if (!params.reqGenres.includes(genre)) {
                            console.log('+++ req genres changed')
                            setParams({
                                reqGenres: [...params.reqGenres, genre],
                            })
                        }
                    }}
                    // setCountry={(country) => {
                    //     console.log('+++ country changed')
                    //     setParams({
                    //         country: country,
                    //     })
                    // }}
                    setLanguage={(language) => {
                        console.log('+++ language changed')
                        setParams({
                            language: language,
                        })
                    }}
                />
            ))
        )
    } else {
        if (!state.loading) {
            if (!state.error) {
                return (
                    <NoMoviesMessage>
                        No movies match your criteria
                    </NoMoviesMessage>
                )
            } else {
                return (
                    <NoMoviesMessage>
                        Error fetching data: {state.error.message}. Please reload page
                    </NoMoviesMessage>
                )
            }
        } else {
            return (
                <NoMoviesMessage>
                    Loading...
                </NoMoviesMessage>
            )
        }
    }
}

const NoMoviesMessage = styled.div`
    display: flex;
    justify-content: center;
    padding: 30px;
`

