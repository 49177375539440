import * as AppConst from './AppConst'
import * as Utils from './Utils'

export function getParams(searchParams) {
    const [yearFrom, yearTo] = getYears(searchParams)
    return {
        sort: getSort(searchParams),
        yearFrom,
        yearTo,
        title: getTitle(searchParams),
        director: getDirector(searchParams),
        actor: getActor(searchParams),
        reqGenres: getGenres(searchParams, 'req_genres'),
        skipGenres: getGenres(searchParams, 'skip_genres'),
        // country: getCountry(searchParams),
        language: getLanguage(searchParams),
    }
}

function getSort(searchParams) {
    let value = parseInt(searchParams.get('sort'))
    if (isNaN(value) || value < AppConst.minSort || value > AppConst.maxSort) {
        value = AppConst.sort
    }
    return value
}

function getYears(searchParams) {
    let from = parseInt(searchParams.get('year_from'))
    if (isNaN(from)
            || from < AppConst.minYear
            || from > AppConst.maxYear) {
        from = AppConst.minYear
    }
    let to = parseInt(searchParams.get('year_to'))
    if (isNaN(to)
            || to < AppConst.minYear
            || to > AppConst.maxYear) {
        to = AppConst.maxYear
    }
    if (from > to) {
        from = AppConst.minYear
        to = AppConst.maxYear
    }
    return [from, to]
}

function getTitle(searchParams) {
    let value = searchParams.get('title')
    if (value === null) {
        value = AppConst.title
    }
    return value
}

function getDirector(searchParams) {
    let value = searchParams.get('director')
    if (value === null) {
        value = AppConst.director
    }
    return value
}

function getActor(searchParams) {
    let value = searchParams.get('actor')
    if (value === null) {
        value = AppConst.actor
    }
    return value
}

function getGenres(searchParams, param) {
    let str = searchParams.get(param)
    if (str === null) {
        return []
    }
    let genres = strToGenres(str)
    genres = [...new Set(genres)]
    return genres
}

function strToGenres(str) {
    return str.split('_')
}

// function getCountry(searchParams) {
//     let value = searchParams.get('country')
//     if (value === null) {
//         value = AppConst.country
//     }
//     return value
// }

function getLanguage(searchParams) {
    let value = searchParams.get('language')
    if (value === null) {
        value = AppConst.language
    }
    return value
}

export function getDefaultValues() {
    return {
        sort: AppConst.sort,
        yearFrom: AppConst.minYear,
        yearTo: AppConst.maxYear,
        title: AppConst.title,
        director: AppConst.director,
        actor: AppConst.actor,
        reqGenres: AppConst.reqGenres,
        skipGenres: AppConst.skipGenres,
        // country: AppConst.country,
        language: AppConst.language,
    }
}

export function convertParams(params) {
    const defaultValues = getDefaultValues()

    const params2 = {}
    Object.entries(defaultValues).forEach(([key, value]) => {
        if (params[key] !== undefined && !Utils.deepEqual(params[key], value)) {
            params2[key] = params[key]
        }
    })

    const params3 = {}
    Object.entries(params2).forEach(([key, value]) => {
        if (key === 'yearFrom') {
            params3.year_from = params2.yearFrom
        } else if (key === 'yearTo') {
            params3.year_to = params2.yearTo
        } else if (key === 'reqGenres') {
            params3.req_genres = genresToStr(params2.reqGenres)
        } else if (key === 'skipGenres') {
            params3.skip_genres = genresToStr(params2.skipGenres)
        } else {
            params3[key] = value
        }
    })

    return params3
}

function genresToStr(genres) {
    return genres.join('_')
}

// function cleanGenres(genres, allGenres) {
//     return genres.filter((genre) => allGenres.includes(genre))
// }
