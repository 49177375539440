import React, { useEffect } from 'react'
import styled from 'styled-components'

import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'

import * as AppConst from './AppConst'

export default function YearsComp({ yearRange: yearRangeProp, setYearRange: setYearRangeProp }) {
    const [yearRange, setYearRange] = React.useState(yearRangeProp)

    useEffect(() => {
        setYearRange(yearRangeProp)
    }, [yearRangeProp])

    const handleYearChange = (event, newValue) => {
        setYearRange(newValue)
    }

    const handleYearChange2 = (event, newValue) => {
        if (arraysEqual(yearRangeProp, newValue)) {
            return
        }
        setYearRangeProp(newValue)
    }

    return (
        <Cont>
            <Typography>
                Period: {yearRange[0] !== AppConst.minYear ? yearRange[0] : '...'} - {yearRange[1]}
            </Typography>
            <Slider
                value={yearRange}
                onChange={handleYearChange}
                onChangeCommitted={handleYearChange2}
                step={1}
                min={AppConst.minYear}
                max={AppConst.maxYear}
                disableSwap
            />
        </Cont>
    )
}

const Cont = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0px 10px 0px 10px;
`

function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
  
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }
