import React from 'react'

import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import IconButton from '@mui/material/IconButton'
import MenuItem from "@mui/material/MenuItem"

import HomeIcon from '@mui/icons-material/Home'
import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowForward from '@mui/icons-material/ArrowForward'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'

import * as Utils from './Utils'
import { getDefaultValues } from './ParamUtils'
import MenuButton from './MenuButton'

function isStandalone() {
    return window.matchMedia('(display-mode: standalone)').matches
}

export default function ToolBar({params, setParams}) {
    const navigate = useNavigate()
    const buttonStyle = {
        // padding: '6px',
    }
    const menuApi = {
        close: event => {},
    }
    const items = [
        <MenuItem
            key="forum"
            onClick={e => {
                menuApi.close(e)
                window.open('https://discord.gg/ksCAPVAS', '_blank')
            }}
        >
            Forum on Discord
        </MenuItem>,
    ]
    return (
        <ToolPanel>
            {isStandalone() &&
                <>
                    <IconButton
                        aria-label="back"
                        sx={buttonStyle}
                        onClick={() => navigate(-1)}
                    >
                        <ArrowBack />
                    </IconButton>
                    <IconButton
                        aria-label="forward"
                        sx={{...buttonStyle, marginRight: '10px'}}
                        onClick={() => navigate(1)}
                    >
                        <ArrowForward />
                    </IconButton>
                </>
            }
            <IconButton
                color="primary"
                aria-label="home"
                sx={buttonStyle}
                disabled={Utils.deepEqual(params, getDefaultValues())}
                onClick={() => {
                    // const defaultValues = getDefaultValues()
                    // if (!Utils.deepEqual(params, defaultValues)) {
                    //     setParams(defaultValues)
                    // }
                    setParams(getDefaultValues())
                }}
            >
                <HomeIcon
                    fontSize="large"
                />
            </IconButton>
            <ToolSpringer />
            <MenuButton
                icon={
                    <MenuRoundedIcon
                        fontSize="large"
                    />
                }
                menuRenderer={items}
                menuApi={menuApi}
            />
        </ToolPanel>
    )
}

const ToolPanel = styled.div`
  display: flex;
  /* gap: 2px; */
`

const ToolSpringer = styled.div`
  flex-grow: 1;
`

