import React from 'react'

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'

export default function SortComp({ sort, setSort }) {
    const sortMarks = [
        {
            value: 0,
            label: 'Popularity',
        },
        {
            value: 1,
            label: 'Popularity and rating',
        },
        {
            value: 2,
            label: 'Rating',
        },
        {
            value: 3,
            label: 'Realease year',
        },
    ]

    const handleSortChange = (event) => {
        setSort(event.target.value)
    }

    return (
        <FormControl>
            <InputLabel id="sort-label">Sort</InputLabel>
            <Select
                labelId="sort-label"
                value={sort}
                onChange={handleSortChange}
                label='Sort by'
                // size="small"
            >
                {sortMarks.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}